{
  "menu":
    {
      "commercial": "Commercial",
      "contracts": "Contracts",
      "plans": "Plans",
      "price_groups": "Price groups",
      "final_balance": "Final balance",
      "map": "Map",
      "news": "News",
      "emails": "Email",
      "cross_tenant": "Cross tenant",
      "public_configs": "Public configs",
      "home": "Home",
      "default_subscriptions": "Default subscriptions",
      "system_alarm_configuration": "System alarm configuration",
    },
  "home":
    {
      "total_supervisors": "Total supervisors",
      "new_supervisors": "New supervisors",
      "total_tenants": "Total tenants",
      "simple_supervisors": "Simple supervisors",
      "standard_supervisors": "Standard supervisors",
      "supervisors_by_tenant": "Supervisors by tenants",
    },
  "common":
    {
      "name": "Name",
      "device_models": "Device models",
      "device_model": "Device model",
      "metavariables": "Metavariables",
      "metavariable": "Metavariable",
      "search": "Search",
      "language": "Language",
      "timezone": "Timezone",
      "domain": "Domain",
      "networks": "Networks",
      "otp_required": "Otp required",
      "client_portal": "Client portal",
      "end_date": "End date",
      "start_date": "Start date",
      "plan": "Plan",
      "emails": "Email",
      "edit": "Edit {0}",
      "delete": "Delete",
      "cancel": "Cancel",
      "save": "Save",
      "type": "Type",
      "add": "Add",
      "title": "Title {0}",
      "select_date_and_time": "Select date and time",
      "text": "Text {0}",
      "datetime": "Datetime",
      "confirm_undoable_action": "Are you sure? This action can't be undone",
      "first_name": "First name",
      "last_name": "Last name",
      "active": "Attivo",
      "group": "Group | Groups",
      "select_item": "Select time",
      "supervisors": "Supervisors",
      "tenant": "Tenant",
      "count": "Count",
      "price": "Price",
      "total": "Total",
      "send": "Send",
      "add_row": "Add row",
    },
  "errors": { "generic": "Something went wrong" },
  "success":
    {
      "tenant_updated": "Tenant updated",
      "contract_updated": "Contract updated",
      "plan_updated": "Plan updated",
      "plan_created": "Plan created",
      "price_group_updated": "Price group updated",
      "price_group_created": "Price group created",
      "news_updated": "News updated",
      "news_created": "News created",
      "email_created": "Email created",
      "email_updated": "Email updated",
      "email_deleted": "Email deleted",
      "email_sent": "Email sent",
      "element_synchronized": "Element synchronized",
      "default_subscription_updated": "Default subscription updated",
      "default_subscription_created": "Default subscription created",
      "default_subscription_copied": "Default subscription copied",
      "configuration_updated": "Configuration updated",
      "final_balance_sent": "Final balance sent"
    },
  "tenants":
    {
      "sender_email": "Sender email",
      "database_max_days": "Max numerb of days in db",
      "max_login_attempts": "Max number of login attempts",
      "operation_log_days": "Operation log days",
    },
  "contracts":
    {
      "billing_period": "Billing period",
      "next_bill": "Next bill",
      "days_active_after_contract_due_date": "Days active after contract due date",
      "days_before_bill_due_date": "Days before bill due date",
      "days_before_contract_due_date": "Dsys before contract due date",
      "amarc_emails": "Email amarc",
      "dhs_commission": "DHS commission",
      "contract": "Contract",
      "ANNUAL": "Annual",
      "MONTHLY": "Monthly",
      "BIMONTHLY": "Bimonthly",
      "QUARTERLY": "Quarterly",
      "BIANNUAL": "Biannual",
    },
  "plan":
    {
      "fixed_price": "Fixed price",
      "new": "New plan",
      "standard_supervisor_price": "Standard supervisor price",
      "simple_supervisor_price": "Simple supervisor price",
      "price": "Price",
      "new_price_group": "New price group",
    },
    "final_balance": {
      "compute": "Compute",
      "total": "Total",
      "partial_data": "Partial data updated at",
      "tenant_list": "Tenant list",
      "supervisor_type": "Supervisor type"
    },
  "news": { 
    "published": "Published",
    "all_clients": "All tenants",
  },
  "emails":
    {
      "addressees_number": "Addressee number",
      "sent": "Sent",
      "send": "Send",
      "email": "Email",
      "datetime_message": "Datetime is in UTC",
      "schedule_time": "Scheduled time",
      "filter_users": "Filter users",
      "search_users": "Serach users",
      "filter_tenant": "Filter tenant",
      "select_tenant": "Select tenant",
      "filter_group": "Filter group",
      "select_group": "Select group",
    },
  "cross_tenant":
    {
      "missing_model": "Missin model",
      "select_element": "Selct element to synchronize",
      "tenant_list": "Tenant list",
      "tenant_list_subtitle": "Choose on which tenants the element qill be imported",
      "synchronize": "Synchronize",
      "trend_template": "Trend template | Trend templates",
      "reading_template": "Reading template | Reading templates",
      "alarm_device_model_configuration": "Alarm device model configuration | Alarm device model configurations",
      "hmi_layout": "Layout hmi",
      "analysis_rule": "Analysis rule | Analysis rules",
    },
  "public_config": { "new_default_subscription": "New default subscription" },
  "$vuetify":
    {
      "badge": "Badge",
      "noDataText": "No data available",
      "close": "Close",
      "open": "open",
      "dataFooter":
        {
          "itemsPerPageText": "Items per page:",
          "itemsPerPageAll": "All",
          "pageText": "{0}-{1} of {2}",
          "firstPage": "First Page",
          "prevPage": "Previous Page",
          "nextPage": "Next Page",
          "lastPage": "Last Page",
        },
      "pagination":
        {
          "ariaLabel":
            {
              "root": "root",
              "previous": "previous",
              "next": "next",
              "currentPage": "currentPage",
              "page": "page",
              "first": "first",
              "last": "last",
            },
        },
      "input":
        {
          "clear": "clear",
          "appendAction": "appendAction",
          "prependAction": "prependAction",
          "counterSize": "counterSize",
          "otp": "otp",
        },
      "fileInput": { "counterSize": "counterSize" },
      "rating": { "ariaLabel": { "item": "item" } },
    },
    "currencies":{
      "EUR": "€"
    }
}

import _definePage_default_0 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/[...404].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_4 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/login.vue?definePage&vue'
import _definePage_default_5 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/reset/[uid]/[token].vue?definePage&vue'
import _definePage_default_6 from '/codebuild/output/src2751296081/src/tenant_portal/src/pages/two-steps.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:404(.*)',
    name: '$404',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/[...404].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/commercial',
    /* internal name: 'commercial' */
    /* no component */
    children: [
      {
        path: 'contracts',
        name: 'commercial-contracts',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/commercial/contracts.vue'),
        /* no children */
      },
      {
        path: 'final-balance',
        name: 'commercial-final-balance',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/commercial/final-balance.vue'),
        /* no children */
      },
      {
        path: 'plans',
        name: 'commercial-plans',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/commercial/plans.vue'),
        /* no children */
      },
      {
        path: 'price-groups',
        name: 'commercial-price-groups',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/commercial/price-groups.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/cross-tenant',
    /* internal name: 'cross-tenant' */
    /* no component */
    children: [
      {
        path: 'alarm-configurations',
        name: 'cross-tenant-alarm-configurations',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/alarm-configurations.vue'),
        /* no children */
      },
      {
        path: 'analysis-rules',
        name: 'cross-tenant-analysis-rules',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/analysis-rules.vue'),
        /* no children */
      },
      {
        path: 'device-models',
        name: 'cross-tenant-device-models',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/device-models.vue'),
        /* no children */
      },
      {
        path: 'hmi-layouts',
        name: 'cross-tenant-hmi-layouts',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/hmi-layouts.vue'),
        /* no children */
      },
      {
        path: 'metavariables',
        name: 'cross-tenant-metavariables',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/metavariables.vue'),
        /* no children */
      },
      {
        path: 'reading-templates',
        name: 'cross-tenant-reading-templates',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/reading-templates.vue'),
        /* no children */
      },
      {
        path: 'trend-templates',
        name: 'cross-tenant-trend-templates',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/cross-tenant/trend-templates.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/emails',
    name: 'emails',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/emails.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  {
    path: '/map',
    name: 'map',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/map.vue'),
    /* no children */
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/news.vue'),
    /* no children */
  },
  {
    path: '/public-config',
    /* internal name: 'public-config' */
    /* no component */
    children: [
      {
        path: 'default-subscriptions',
        name: 'public-config-default-subscriptions',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/public-config/default-subscriptions.vue'),
        /* no children */
      },
      {
        path: 'system-alarm',
        name: 'public-config-system-alarm',
        component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/public-config/system-alarm.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/reset',
    /* internal name: 'reset' */
    /* no component */
    children: [
      {
        path: ':uid',
        /* internal name: 'reset-uid' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':token',
            name: 'reset-uid-token',
            component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/reset/[uid]/[token].vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      }
    ],
  },
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/tenants.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/two-steps',
    name: 'two-steps',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/two-steps.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import('/codebuild/output/src2751296081/src/tenant_portal/src/pages/user-settings.vue'),
    /* no children */
  }
]

{
  "menu": {
    "commercial": "Commerciale",
    "contracts": "Contratti",
    "plans": "Piani",
    "price_groups": "Gruppi di prezzo",
    "final_balance": "Consuntivazione",
    "map": "Mappa",
    "news": "Novità",
    "emails": "Email",
    "cross_tenant": "Cross tenant",
    "public_configs": "Configurazioni condivise",
    "tenants": "Tenants",
    "home": "Home",
    "default_subscriptions": "Sottoscrizioni di default",
    "system_alarm_configuration": "Configurazione allarmi di sistema"
  },
  "home": {
    "total_supervisors": "Totale utenze",
    "new_supervisors": "Nuove utenze",
    "total_tenants": "Totale tenants",
    "simple_supervisors": "Utenze semplici",
    "standard_supervisors": "Utenze standard",
    "supervisors_by_tenant": "Utenze per tenant"
  },
  "common": {
    "name": "Nome",
    "device_models": "Modelli dispositivo",
    "device_model": "Modello dispositivo",
    "metavariables": "Metavariabili",
    "metavariable": "Metavariabile",
    "search": "Cerca",
    "language": "Lingua",
    "timezone": "Fuso orario",
    "domain": "Dominio",
    "networks": "Reti",
    "otp_required": "Otp obbligatoria",
    "client_portal": "Portale clienti",
    "end_date": "Data fine",
    "start_date": "Data inizio",
    "plan": "Piano",
    "emails": "Email",
    "edit": "Modifica {0}",
    "delete": "Elimina",
    "cancel": "Annulla",
    "save": "Salva",
    "type": "Tipo",
    "add": "Aggiungi",
    "title": "Titolo {0}",
    "select_date_and_time": "Seleziona giorno e ora",
    "text": "Testo {0}",
    "datetime": "Data e ora",
    "confirm_undoable_action": "Sei sicuro? Questa azione non può essere annullata",
    "first_name": "Nome",
    "last_name": "Cognome",
    "active": "Attivo",
    "group": "Gruppo | Gruppi",
    "select_item": "Seleziona opzione",
    "supervisors": "Utenze",
    "tenant": "Tenant",
    "count": "Conteggio",
    "price": "Prezzo",
    "total": "Totale",
    "send": "Invia",
    "add_row": "Aggiungi riga",
  },
  "errors": {
    "generic": "Qualcosa non ha funzionato"
  },
  "success": {
    "tenant_updated": "Tenant aggiornato",
    "contract_updated": "Contratto aggiornato",
    "plan_updated": "Piano aggiornato",
    "plan_created": "Piano creato",
    "price_group_updated": "Gruppo di prezzo aggiornato",
    "price_group_created": "Gruppo di prezzo creato",
    "news_updated": "News aggiornata",
    "news_created": "News creata",
    "email_created": "Email creata",
    "email_updated": "Email aggiornata",
    "email_deleted": "Email eliminata",
    "email_sent": "Email inviata",
    "element_synchronized": "Elemento sincronizzato",
    "default_subscription_updated": "Sottoscrizione di default aggiornata",
    "default_subscription_created": "Sottoscrizione di default creata",
    "default_subscription_copied": "Sottoscrizione di default copiata",
    "configuration_updated": "Configurazione aggiornata",
    "final_balance_sent": "Consuntivo inviato"
  },
  "tenants": {
    "sender_email": "Email mittente",
    "database_max_days": "Numero massimo di giorni nel database",
    "max_login_attempts": "Massimo numero di login errati",
    "operation_log_days": "Giorni di log operativi",
    "energy_metavariable_pk": "Chiave metavariabile energia"
  },
  "contracts": {
    "billing_period": "Periodo di fatturazione",
    "next_bill": "Prossima fattura",
    "days_active_after_contract_due_date": "Giorni di preavviso scadenza",
    "days_before_bill_due_date": "Giorni di preavviso fattura",
    "days_before_contract_due_date": "Giorni attivi dopo la scadenza",
    "amarc_emails": "Email amarc",
    "dhs_commission": "Commessa DHS",
    "contract": "Contratto",
    "ANNUAL": "Annuale",
    "MONTHLY": "Mensile",
    "BIMONTHLY": "Bimestrale",
    "QUARTERLY": "Trimestrale",
    "BIANNUAL": "Semestrale",
  },
  "plan": {
    "fixed_price": "Prezzo fisso",
    "new": "Nuovo piano",
    "standard_supervisor_price": "Prezzo utenza standard",
    "simple_supervisor_price": "Prezzo utenza semplice",
    "price": "Prezzo",
    "new_price_group": "Nuovo gruppo di prezzo",
  },
  "final_balance": {
    "compute": "Calcola",
    "total": "Totale",
    "partial_data": "Dati parziali aggiornati a",
    "tenant_list": "Elenco tenant",
    "supervisor_type": "Tipo utenze"
  },
  "news": {
    "published": "Pubblicata",
    "all_clients": "Tutti i tenant",
  },
  "emails": {
    "addressees_number": "Numero destinatari",
    "sent": "Inviata",
    "send": "Invia",
    "email": "Email",
    "datetime_message": "Data in UTC",
    "schedule_time": "Orario invio",
    "filter_users": "Filtra utenti",
    "search_users": "Cerca utenti",
    "filter_tenant": "Cerca tenant",
    "select_tenant": "Seleziona tenant",
    "filter_group": "Cerca gruppo",
    "select_group": "Seleziona gruppo",
  },
  "cross_tenant": {
    "missing_model": "Modello mancante",
    "select_element": "Seleziona elemento da copiare",
    "tenant_list": "Elenco tenant",
    "tenant_list_subtitle": "Seleziona su quali tenant importare l'elemento selezionato",
    "synchronize": "Sincronizza",
    "trend_template": "Modello trend | Modelli trend",
    "reading_template": "Modello letture | Modelli letture",
    "alarm_device_model_configuration": "Configurazione allarmi modelli dispositivo | Configurazioni allarmi modelli dispositivo",
    "hmi_layout": "Layout hmi",
    "analysis_rule": "Regola analisi | Regole analisi",
  },
  "public_config": {
    "new_default_subscription": "Nuova sottoscrizione di default"
  },
  "$vuetify": {
    "badge": "Badge",
    "noDataText": "Nessun dato disponibile",
    "close": "Close",
    "open": "open",
    "dataFooter": {
      "itemsPerPageText": "Righe per pagina:",
      "itemsPerPageAll": "All",
      "pageText": "{0}-{1} of {2}",
      "firstPage": "First Page",
      "prevPage": "Previous Page",
      "nextPage": "Next Page",
      "lastPage": "Last Page"
    },
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page",
        "first": "first",
        "last": "last"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize",
      "otp": "otp"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  },
  "currencies":{
    "EUR": "€"
  }
}
